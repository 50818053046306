<template>
  <div id="room-amenities" v-show="!loading">
    <!-- Room Size -->
<!--    <vs-row>-->
<!--      <vs-col vs-w="12" class="mb-base">-->
<!--        <div class="vx-card p-6">-->
<!--          <h5 class="text-base font-semibold mb-5">-->
<!--            Please enter the size of your rooms-->
<!--          </h5>-->

<!--          <div class="vx-row">-->
<!--            <div-->
<!--              class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-4"-->
<!--              v-for="property in roomAmenities"-->
<!--              :key="property.id"-->
<!--            >-->
<!--              <vs-input-->
<!--                :label="property.name"-->
<!--                class="w-full"-->
<!--                icon-after="true"-->
<!--                icon="directions_walk"-->
<!--                v-model="property.size"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </vs-col>-->
<!--    </vs-row>-->

<!--    <div class="vx-col w-full" v-show="rooms.length > 0">-->
<!--      <div id="page-rooms-detail-list">-->
<!--        <div class="vx-row">-->
<!--          <div-->
<!--            class="vx-col w-full md:w-6/12 lg:w-4/12 mb-base"-->
<!--            v-for="room in rooms"-->
<!--            :key="room.id"-->
<!--          >-->
<!--            <div class="vx-card">-->
<!--              &lt;!&ndash; Card Header &ndash;&gt;-->
<!--              <div class="vx-row p-6">-->
<!--                <div class="vx-col w-full">-->
<!--                  <h4 class="text-xl mb-2">{{ room.name }}</h4>-->
<!--                  <span class="text-grey text-base">{{-->
<!--                      room.roomName-->
<!--                    }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Card Image &ndash;&gt;-->
<!--              <div class="vx-row">-->
<!--                <div class="vx-col w-full">-->
<!--                  <img-->
<!--                    class="block w-full h-64"-->
<!--                    :src="findPropertyMainPhoto(room)"-->
<!--                    alt=""-->
<!--                    style="object-fit: cover !important"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Card Content &ndash;&gt;-->
<!--              <div class="vx-row p-8">-->
<!--                <div class="vx-col w-full">-->
<!--                  Maximum Guest :-->
<!--                  <span class="font-semibold"-->
<!--                  >{{ room.maxOccupancy }} guest</span-->
<!--                  >-->
<!--                </div>-->
<!--                <vs-divider></vs-divider>-->
<!--                <div class="vx-col w-full">-->
<!--                  Maximum Adult :-->
<!--                  <span class="font-semibold"-->
<!--                  >{{ room.maxAdults }} adult</span-->
<!--                  >-->
<!--                </div>-->
<!--                <vs-divider></vs-divider>-->
<!--                <div class="vx-col w-full">-->
<!--                  Maximum Child :-->
<!--                  <span class="font-semibold"-->
<!--                  >{{ room.maxChildren }} child</span-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash; Card Footer &ndash;&gt;-->
<!--              <div class="vx-row p-6">-->
<!--                <div class="vx-col w-full text-center">-->
<!--                  <router-link-->
<!--                    :to="{-->
<!--                      name: 'vancynet-room-amenities-edit',-->
<!--                      params: { id: room.id },-->
<!--                    }"-->
<!--                    class="text-primary select-none cursor-pointer"-->
<!--                  >Edit Amenities ({{ room.amenities.length }})-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <vs-row class="mb-4">
      <vs-col vs-lg="12" vs-md="12" vs-xs="12">
        <div class="vx-card p-6">
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                    v-model="search" placeholder="Search..." />
        </div>
      </vs-col>
    </vs-row>

    <!-- Room Amenities -->
    <vs-row id="list-room-amenities">
      <vs-col
        vs-w="12"
        class="mb-base"
        v-for="amenities in filteredAmenities"
        :key="amenities.id"
      >
        <div class="vx-card p-6">
          <h3 class="text-xl font-semibold mb-5">
            {{ amenities.name }}
          </h3>


            <el-row class="w-full" :gutter="10">
              <el-col v-for="(room) in amenities.rooms"
                      :key="room.id"
                      :span="8">
                <vs-checkbox
                  v-model="room.checked"
                  @change="updateAmenities(room.id)"
                  class="mb-3">&nbsp;{{ room.name }}</vs-checkbox>
              </el-col>
            </el-row>

<!--            <div class="vx-col w-full">-->
<!--              <div class="vx-row items-start">-->
<!--                <div class="vx-col md:w-1/4 w-1/4">-->
<!--                  {{ room.name }}-->
<!--                </div>-->
<!--                <div class="vx-col w-full md:w-1/2">-->
<!--                  <div class="vx-row">-->
<!--                    <div-->
<!--                      class="w-full lg:w-1/2"-->
<!--                      v-for="product_property in roomAmenities"-->
<!--                      :key="product_property.id"-->
<!--                    >-->
<!--                      <vs-checkbox-->
<!--                        class="mb-3"-->
<!--                        v-model="facility.product_properties"-->
<!--                        :id="product_property.id"-->
<!--                        :vs-value="product_property.id"-->
<!--                        >{{ product_property.name }}</vs-checkbox-->
<!--                      >-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
        </div>
      </vs-col>
    </vs-row>

<!--    <vs-button @click="storeData">Save Changes</vs-button>-->
  </div>
</template>

<script>
import axios from "@/axios";
import { mapActions } from 'vuex'

export default {
  name: "VancyNetRoomAmenities",
  metaInfo: {
    title: "Room Amenities",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      rooms: [],
      roomAmenities: [],
      facilityCategories: [],
      search: '',
      loading: true
    };
  },
  computed: {
    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },
    filteredAmenities() {
      if (this.search.length > 2) {
        return this.roomAmenities.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      return this.roomAmenities;
    }
  },
  methods: {
    ...mapActions({
      'getRoomList': 'room/getRoomList',
      'getAmenities': 'amenity/getAmenities',
      'putAmenities': 'amenity/putAmenities',
    }),
    ...mapActions('amenity', ['getAmenities']),
    setAllRoom(facility) {
      console.log(facility);
      console.log("all_room");
    },
    setSomeRoom(facility) {
      console.log(facility);
      console.log("some_room");
    },

    setEmptyRoom(facility) {
      console.log(facility);
      console.log("empty_room");
    },

    async getData() {
      this.$vs.loading({
        type: 'sound'
      })
      try {
        this.rooms = await this.getRoomList({ hotelId: this.hotelId });
        this.roomAmenities = await this.getAmenities();
        for (const ame of this.roomAmenities) {
          ame.rooms = this.rooms.map(r => ({
            id: r.id,
            idAmenities: ame.id,
            name: r.name,
            checked: !!r.amenities.find(a => a.id === ame.id),
          }));
        }
      } catch (e) {
        this.$catchErrorResponse(e)
      } finally {
        this.loading = false
        this.$vs.loading.close()
      }
    },
    findPropertyMainPhoto(room) {
      const mainPhoto = room.images.find(image => image.isMain) ??
        { url: 'https://dummyimage.com/600x400/e6e6e6/3b3b3b&text=Main+photo+is+not+available'};
      return mainPhoto.url;
    },
    async updateAmenities(roomId) {
      const payload = {
        amenitiesIds: [],
      }
      for (const ame of this.roomAmenities) {
        const rooms = ame.rooms.filter(r => r.id === roomId && r.checked === true);
        if (rooms.length > 0) {
          payload.amenitiesIds.push(ame.id);
        }
      }

      try {
        await this.putAmenities({ hotelId: this.hotelId, roomId, payload });
        this.$vs.notify({
          title: "Success",
          text: 'Update facilities success',
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success"
        });
      } catch (e) {
      }
    },
    storeData() {
      let facilities = []
      this.facilityCategories.map(category => {
        category.room_features.map(feature => facilities.push(feature))
      })

      const formData = new FormData()
      formData.set('product_properties', JSON.stringify(this.roomAmenities))
      formData.set('facilities', JSON.stringify(facilities))

      axios.post(`vancynet/room-amenities`, formData)
           .then(({data: res}) => {
             this.$vs.notify({
              title: "Success",
              text: res.msg_status,
              iconPack: "feather",
              icon: "icon-check-circle",
              color: "success"
            });

            this.getData()
           })
           .catch(err => this.$catchErrorResponse(err))
    }
  },
  async created() {
    await this.getData();
  }
};
</script>

<style>
#room-amenities #list-room-amenities .vs-button {
  border-radius: 0 !important;
}

.con-vs-radio, .con-vs-checkbox {
  display: flex !important;
  align-items: flex-start !important;
}
</style>
